import React, { Component } from "react";
import { Input, Button, Toast } from 'antd-mobile'

import { AudioQualityMode, CallsApiEvent, createInfobipRtc, InfobipRTCEvent, PhoneCallOptions } from "infobip-rtc";
import httpClient from "axios";

const audioQualityModes = {
	"Low": AudioQualityMode.LOW_DATA,
	"Auto": AudioQualityMode.AUTO,
	"High": AudioQualityMode.HIGH_QUALITY
}


class PhoneCall extends Component {

	constructor(props) {
		super(props);
		this.state = {
			title: 'Infobip RTC Call Showcase',
			destination: '',
			infobipRTC: null,
			activeCall: null,
			identity: '',
			status: '',
			audioInputDevices: [],
			selectedAudioQualityMode: "Auto"
		};


	}

	componentDidMount() {
		this.connectInfobipRTC();
	}

	connectInfobipRTC = async () => {
		const queryParams = new URLSearchParams(window.location.search)
		let phone = queryParams.get("phone")
		let token = queryParams.get("token")
		// const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2MiOiI3NDQ4NzgzRkY0MDU1RDNFODUzMTVBRDI3QkJDRkQ2NiIsImRzdCI6bnVsbCwiaWRlbnRpdHkiOiJ0ZXN0c3RhZmYwMyIsImlzcyI6IkluZm9iaXAiLCJuYW1lIjoidGVzdCBuYW1lIDA1IiwibG9jYXRpb24iOiIiLCJleHAiOjE3MTc0OTc2MzEsImNhcHMiOlsyMF19._O1NCngBDTCSH7CFOZHyUhqXPj20QpzM28xhzGR1FKA'

		if(!phone || !token) {
			Toast.show('Phone or Token ERROR')
			return false
		}
		Toast.show({
			icon: 'loading',
			content: 'Infobip RTC Connecting...',
			duration: 0,
			maskClickable: false
		})
		this.setState((state) => {
			state.destination = `+${phone?.trim()}`
			state.infobipRTC = createInfobipRtc(token, { debug: false });
			state.infobipRTC.on(InfobipRTCEvent.CONNECTED, event => {
				Toast.clear()
				this.setState({ identity: event.identity });
			});
			state.infobipRTC.on(InfobipRTCEvent.DISCONNECTED, event => {
				Toast.show({
					icon: 'fail',
					content: 'Disconnected from Infobip RTC Cloud.'
				})
			});
			state.infobipRTC.connect();
			this.loadAudioDevices();
			return state;
		});
		// httpClient.post('http://localhost:8080/token')
		//     .then((response) => {

		//     })
		//     .catch(err => {
		//         console.error(err);
		//     });
	};

	loadAudioDevices = () => {
		this.state.infobipRTC.getAudioInputDevices().then(inputDevices => this.setState({ audioInputDevices: inputDevices }));
	}

	setCallEventHandlers = (call) => {
		call.on(CallsApiEvent.RINGING, () => {
			this.setState({ status: 'Ringing...' });
			console.log('Call is ringing...');
		});
		call.on(CallsApiEvent.ESTABLISHED, event => {
			this.setState({ status: 'Call established with: ' + this.state.activeCall.counterpart().identifier });
			console.log('Call established with ' + this.state.activeCall.counterpart().identifier);
			this.setMediaStream(this.refs.remoteAudio, event.stream);
		});
		call.on(CallsApiEvent.HANGUP, event => {
			console.log(event)
			if(event.errorCode.name != "NORMAL_HANGUP") {
				Toast.show({
					content: JSON.stringify(event.errorCode.description)
				})
			}
			this.setState({ status: 'Call finished: ' + event.errorCode.name });
			this.setMediaStream(this.refs.remoteAudio, null);
			this.setValuesAfterCall();
		});
		call.on(CallsApiEvent.ERROR, event => {
			Toast.show({
				icon: 'fail',
				content: 'Oops, something went very wrong! Message: ' + JSON.stringify(event)
			})
			console.log('Oops, something went very wrong! Message: ' + JSON.stringify(event));
		});
	}

	setMediaStream = (element, stream) => {
		element.srcObject = stream;
	}

	handleChange = (dest) => {
		this.setState({ destination: dest });
	};

	callPhone = () => {
		if (this.state.destination) {
			let phoneCallOptions = PhoneCallOptions.builder()
				.setFrom('33712345678')
				.build();

			const activeCall = this.state.infobipRTC.callPhone(this.state.destination);
			console.log(activeCall)
			this.setCallEventHandlers(activeCall);
			this.setState({ activeCall: activeCall });
		}
	};

	hangup = () => {
		this.state.activeCall.hangup();
	};

	shouldDisableHangupButton = () => {
		return !this.state.activeCall || (!this.state.isCallEstablished && this.state.isIncomingCall);
	}

	setValuesAfterCall = () => {
		this.setState({
			activeCall: null,
			status: '',
			selectedAudioQualityMode: "Auto"
		});
	}

	onAudioInputDeviceChange = async (event) => {
		const deviceId = event.target.value;
		const { activeCall } = this.state;
		if (!!activeCall) {
			await activeCall.setAudioInputDevice(deviceId);
		}
	}

	onAudioQualityChange = event => {
		const audioQuality = event.target.value;
		const { activeCall } = this.state;
		this.setState({ selectedAudioQualityMode: audioQuality });

		if (!!activeCall) {
			activeCall.setAudioQualityMode(audioQualityModes[audioQuality]);
		}
	}

	render = () => {
		const {
			title,
			identity,
			destination,
			activeCall,
			status,
			audioInputDevices,
			selectedAudioQualityMode
		} = this.state;

		return (
			<div>
				<h2><span>{title}</span></h2>
				<h4>Logged as: <span>{identity}</span></h4>

				<audio ref="remoteAudio" autoPlay />

				<div style={{ border: '1px solid #ccc', borderRadius: 6, marginBottom: 20, padding: '4px 10px' }}>
					<Input
						type="text"
						value={destination}
						disabled
						onChange={this.handleChange}
						placeholder="Enter phone number to call..."
					/>
				</div>

				<Button
					block
					color='primary'
					size='middle'
					disabled={activeCall} onClick={this.callPhone}
				>{!!activeCall ? 'Calling...' : 'Call'}</Button>
				{/* <button disabled={activeCall} onClick={this.callPhone}>Call</button> */}

				<h4><span>{status}</span></h4>

				<Button
					block
					color='danger'
					size='middle'
					disabled={this.shouldDisableHangupButton()}
					onClick={this.hangup}
				>Hangup</Button>
				{/* <button disabled={this.shouldDisableHangupButton()} onClick={this.hangup}>Hangup</button> */}
				<br /><br />

				{!!activeCall &&
					<>
						<label htmlFor={"audio-input-device-select"}>Choose audio input device:</label>
						<br />
						<select id={"audio-input-device-select"} onChange={this.onAudioInputDeviceChange} style={{ width: '100%', height: 40, lineHeight: 40, padding: '0px 15px', border: '1px solid #dcdfe6', borderRadius: 4, outline: 'none', fontSize: 16 }}>
							{audioInputDevices.map(device => <option id={device.deviceId} value={device.deviceId} key={device.deviceId}>{device.label || device.deviceId}</option>)}
						</select>
						<br /><br />

						<label htmlFor={"audio-input-device-select"}>Choose audio quality mode:</label>
						<br />
						<select id={"audio-input-device-select"} onChange={this.onAudioQualityChange} value={selectedAudioQualityMode} style={{ width: '100%', height: 40, lineHeight: 40, padding: '0px 15px', border: '1px solid #dcdfe6', borderRadius: 4, outline: 'none', fontSize: 16 }}>
							{Object.keys(audioQualityModes).map(mode => <option id={mode} value={mode} key={mode}>{mode}</option>)}
						</select>
						<br /><br />
					</>
				}
			</div>
		)
	}
}

export default PhoneCall;
